import React from "react"

function HeroPattern() {
  return (
    <>
      <div>
        <div
          data-sal="fade"
          style={{ "--sal-delay": "2.4s" }}
          data-sal-easing="ease-in"
        >
          <img
            src="https://us-west-2.graphassets.com/AsRMKMrtKTFW6TGbr4KgUz/KHKponMzT2Gfo8aTlrjg"
            alt="anim"
            height="10"
            width="10"
            className="anim-spark position-absolute"
          />
        </div>
        <div
          data-sal="fade"
          style={{ "--sal-delay": "2.4s" }}
          data-sal-easing="ease-in"
        >
          <img
            src="https://us-west-2.graphassets.com/AsRMKMrtKTFW6TGbr4KgUz/5sgDDJkkQcC3hA9KDPAC"
            alt="anim"
            height="10"
            width="10"
            className="anim-wire-straight position-absolute"
          />
        </div>
        <div
          data-sal="fade"
          style={{ "--sal-delay": "2.4s" }}
          data-sal-easing="ease-in"
        >
          <img
            src="https://us-west-2.graphassets.com/AsRMKMrtKTFW6TGbr4KgUz/meZcg2yRna4drnNQaIDY"
            alt="anim"
            height="10"
            width="10"
            className="anim-wire-spiral position-absolute"
          />
        </div>
        <div
          data-sal="fade"
          style={{ "--sal-delay": "2.4s" }}
          data-sal-easing="ease-in"
        >
          <img
            src="https://us-west-2.graphassets.com/AsRMKMrtKTFW6TGbr4KgUz/DuqAIb1qRwWNpJ3aGBJD"
            alt="anim"
            height="10"
            width="10"
            className="anim-slash position-absolute"
          />
        </div>
        <div
          data-sal="fade"
          style={{ "--sal-delay": "2.4s" }}
          data-sal-easing="ease-in"
        >
          <img
            src="https://us-west-2.graphassets.com/AsRMKMrtKTFW6TGbr4KgUz/dJWAxYxZS3i1D3Qfp5q4"
            alt="anim"
            height="10"
            width="10"
            className="anim-document position-absolute"
          />
        </div>
      </div>
    </>
  )
}

export default HeroPattern
