import { graphql, StaticQuery } from "gatsby"
import parse from "html-react-parser"
import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { Element as ScrollElement, Link as ScrollLink } from "react-scroll"
import FeaturedJobs from "../components/careers/featured-jobs"
import HeroPattern from "../components/careers/hero-pattern"
import Jobs from "../components/careers/jobs"
import Mission from "../components/careers/mission"
import PopupCareer from "../components/careers/popup-job-apply-new"
import CustomAccordion from "../components/common/accordian"
import Frame from "../components/common/frame"
import Navigation from "../components/navigation"
import Slide from "../components/utility/slide_content"
import "../styles/component/faq.scss"
import "../styles/component/pricing-faq.scss"
import "../styles/pages/careers.scss"
import Buttons from "./../components/common/button"
import Testimonial from "./../components/common/testimonial-component/testimonial"

function Careers() {
  const [popupVisibility, setPopupVisibility] = useState(false)

  useEffect(() => {
    const intercomShow = () => {
      if (typeof window.Intercom !== "undefined") {
        window.Intercom("show")
      }
    }

    // document.getElementById("chatId2").onclick = function() {
    //   intercomShow()
    // }

    // document.querySelectorAll(
    //   ".faq .outer .accordion .card-body p code"
    // )[0].onclick = function(e) {
    //   intercomShow()
    // }

    // document.querySelectorAll(
    //   ".faq .outer .accordion .card-body p code"
    // )[1].onclick = function(e) {
    //   intercomShow()
    // }

    // document.querySelectorAll(
    //   ".faq .outer .accordion .card-body p code"
    // )[2].onclick = function(e) {
    //   intercomShow()
    // }
  })

  return (
    <>
      <StaticQuery
        query={graphql`
          query contentCareers {
            SuperOps {
              pages(where: { title: "Careers" }) {
                title
                pageBlock {
                  id
                  content {
                    html
                  }
                }
                navigationBlock {
                  name
                  slug
                  isDropdown
                }
                seo {
                  title
                  description
                  keywords
                  image {
                    url
                  }
                }
                pageImages {
                  url
                }
              }
              faqs(where: { page: "Careers" }) {
                category
                question
                answerrt {
                  html
                }
              }
              peopleList(where: { page: "Careers" }, orderBy: position_ASC) {
                name
                title
                position
                testimonial
                background
                cardBgColor
                picture {
                  url(
                    transformation: {
                      document: { output: { format: webp } }
                      image: { resize: { width: 500 } }
                    }
                  )
                }
              }
            }
          }
        `}
        render={data => (
          <div className="careers">
            {data.SuperOps.pages.map((page, i) => {
              const {
                title,
                navigationBlock,
                pageBlock,
                seo,
                pageImages,
              } = page
              return (
                <div key={i}>
                  <Frame seo={seo} hubspotForm>
                    <header>
                      <Navigation links={navigationBlock} page={title} />
                    </header>

                    <div>
                      <PopupCareer
                        visibility={popupVisibility}
                        togglePopup={() => setPopupVisibility(!popupVisibility)}
                        title="Apply Now"
                      />
                    </div>

                    <section className="hero">
                      <Container className="Layout-container">
                        <Row>
                          <Col lg={7} className="left">
                            <div
                              data-sal="fade"
                              style={{ "--sal-delay": "0.1s" }}
                              data-sal-easing="ease-in"
                            >
                              <div className="card-pink position-relative">
                                <div
                                  data-sal="fade"
                                  style={{ "--sal-delay": "0.2s" }}
                                  data-sal-easing="ease-in"
                                >
                                  <div className="p14">
                                    <p>
                                      {" "}
                                      we’re hiring{" "}
                                      <img
                                        src="https://us-west-2.graphassets.com/AsRMKMrtKTFW6TGbr4KgUz/23aLCvweRXWDwwIiLO8y"
                                        alt="smile"
                                        height="16px"
                                        width="16px"
                                      />
                                    </p>
                                  </div>
                                </div>
                                <div
                                  data-sal="fade"
                                  style={{ "--sal-delay": "0.3s" }}
                                  data-sal-easing="ease-in"
                                >
                                  <div className="altv2">
                                    <h1> Calling all superheroes </h1>
                                  </div>
                                </div>
                                <div className="line-wrap position-absolute">
                                  <img
                                    src="https://us-west-2.graphassets.com/AsRMKMrtKTFW6TGbr4KgUz/UHZ6Z389Rhe2Q5yDMf9L"
                                    alt="line"
                                    height="2px"
                                    width="100px"
                                    className="line"
                                  />
                                </div>
                              </div>
                            </div>
                            <div
                              data-sal="fade"
                              style={{ "--sal-delay": ".4s" }}
                              data-sal-easing="ease-in"
                            >
                              <ScrollLink
                                to="CurrentJobs"
                                spy={true}
                                smooth={"easeInOutCubic"}
                                hashSpy={true}
                                offset={-100}
                                duration={5000}
                                delay={0}
                                isDynamic={true}
                              >
                                <Buttons
                                  theme="primary"
                                  text="EXPLORE ALL JOBS"
                                  arrow
                                />
                              </ScrollLink>
                            </div>
                            <Row className="wrap">
                              <Col lg={8}>
                                <div
                                  data-sal="fade"
                                  style={{ "--sal-delay": ".6s" }}
                                  data-sal-easing="ease-in"
                                >
                                  <div className="info">
                                    <Row>
                                      <Col xs={4}>
                                        <div className="altv2">
                                          <h4 className="fw-bold"> 2020 </h4>
                                        </div>
                                        <div className="p14">
                                          <p> founded </p>
                                        </div>
                                      </Col>
                                      <Col xs={4}>
                                        <div className="altv2">
                                          <h4 className="fw-bold"> 50+ </h4>
                                        </div>
                                        <div className="p14">
                                          <p> employees </p>
                                        </div>
                                      </Col>
                                      <Col xs={4}>
                                        <div className="altv2">
                                          <h4 className="fw-bold"> $17M </h4>
                                        </div>
                                        <div className="p14">
                                          <p> funded </p>
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                </div>
                              </Col>
                              <Col lg={4} className="align-content-right">
                                <div
                                  data-sal="fade"
                                  style={{ "--sal-delay": ".8s" }}
                                  data-sal-easing="ease-in"
                                >
                                  <img
                                    src={pageImages[0].url}
                                    alt="employee"
                                    height="100"
                                    width="100"
                                    className="hero-emp-one"
                                  />

                                  <div className="card-green" />
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <Col lg={5} className="right position-relative">
                            <div className="tb">
                              <div
                                data-sal="fade"
                                style={{ "--sal-delay": ".9s" }}
                                data-sal-easing="ease-in"
                              >
                                <div className="top">
                                  <HeroPattern />
                                  <img
                                    src={pageImages[2].url}
                                    alt="employee"
                                    height="100"
                                    width="100"
                                    style={{ "--sal-delay": "1.2s" }}
                                    className="hero-emp-three"
                                  />

                                  <div className="card-blue" />
                                  <img
                                    src={pageImages[3].url}
                                    alt="employee"
                                    width="100"
                                    className="hero-emp-four"
                                    style={{ "--sal-delay": "1s" }}
                                  />
                                </div>
                              </div>
                              <div
                                data-sal="fade"
                                style={{ "--sal-delay": "1.1s" }}
                                data-sal-easing="ease-in"
                              >
                                <div className="bottom">
                                  <div className="card-green-small" />
                                  <img
                                    src={pageImages[1].url}
                                    alt="employee"
                                    height="100"
                                    width="100"
                                    className="hero-emp-two"
                                    style={{ "--sal-delay": "1.1s" }}
                                  />
                                  <div className="card-violet" />
                                </div>
                              </div>

                              <div
                                data-sal="fade"
                                style={{ "--sal-delay": "1.1s" }}
                                data-sal-easing="ease-in"
                              >
                                <img
                                  src={pageImages[0].url}
                                  alt="employee"
                                  height="100"
                                  width="100"
                                  className="hero-emp-one"
                                />
                                <div className="card-violet small" />
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    </section>

                    <section className="about">
                      <Container className="Layout-container">
                        <Row>
                          <Col lg={5} className="left">
                            <div
                              data-sal="fade"
                              style={{ "--sal-delay": ".8s" }}
                              data-sal-easing="ease-in"
                            >
                              <div className="top">
                                <img
                                  src={pageImages[4].url}
                                  alt="employee"
                                  height="100"
                                  width="100"
                                  className="hero-emp-one"
                                />

                                <div className="card-green" />
                              </div>
                            </div>
                            <div
                              data-sal="fade"
                              style={{ "--sal-delay": ".2s" }}
                              data-sal-easing="ease-in"
                            >
                              <div className="bottom">
                                <div className="card-blue" />
                                <img
                                  src={pageImages[5].url}
                                  alt="employee"
                                  height="100"
                                  width="100"
                                  className="hero-emp-three"
                                />
                              </div>
                            </div>
                          </Col>
                          <Col lg={7} className="right">
                            <div className="content">
                              <Slide style={{ "--sal-delay": ".8s" }}>
                                {parse(pageBlock[0].content.html)}
                              </Slide>
                              <Slide style={{ "--sal-delay": ".8s" }}>
                                <div className="p16">
                                  {/* <p>
                                  Every one of us has been on a journey — one
                                  that teaches us something and helps us sharpen
                                  our skills and harness their superpowers.
                                </p> */}
                                  {parse(pageBlock[1].content.html)}
                                </div>
                              </Slide>
                              <Slide style={{ "--sal-delay": ".2s" }}>
                                {/* <blockquote>
                                At SuperOps, we are on a roller-coaster
                                journey to empower the MSP and IT industry with
                                our products.
                              </blockquote> */}
                                {parse(pageBlock[2].content.html)}
                              </Slide>
                              <Slide style={{ "--sal-delay": ".2s" }}>
                                <div className="p16">
                                  {/* <p>
                                  We are building an A-star team of superheroes
                                  who are not afraid to show off their
                                  superpowers, whose values align with ours, and
                                  know what it takes to make a difference in the
                                  world. If this sounds like you, you should
                                  come work with us.
                                </p> */}
                                  {parse(pageBlock[3].content.html)}
                                </div>
                              </Slide>
                              <Slide style={{ "--sal-delay": ".8s" }}>
                                <Buttons
                                  link="/about-us"
                                  theme="primary"
                                  arrow
                                  text="KNOW MORE"
                                />
                              </Slide>
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    </section>

                    <section className="testimonials">
                      <Testimonial
                        type="multiple-3"
                        items={data.SuperOps.peopleList.filter(
                          item => item.position !== "-"
                        )}
                        heading="Why I love it here"
                        swipeable
                      />
                    </section>

                    <section className="featured-jobs">
                      <Container>
                        <Row>
                          <Col lg={12} className="position-relative">
                            <Slide style={{ "--sal-delay": "0.2s" }}>
                              <h2 className="position-relative">
                                {" "}
                                Featured jobs{" "}
                              </h2>
                            </Slide>
                            <Slide style={{ "--sal-delay": "0.2s" }}>
                              <FeaturedJobs />
                            </Slide>
                          </Col>
                        </Row>
                      </Container>
                    </section>

                    <section className="mission">
                      <Container className="Layout-container">
                        <Row>
                          <Col lg={12} className="position-relative">
                            <Slide style={{ "--sal-delay": "0.2s" }}>
                              <Mission />
                            </Slide>
                          </Col>
                        </Row>
                      </Container>
                    </section>

                    <section className="testimonial-single mx-auto">
                      <Testimonial
                        type="single-1"
                        items={data.SuperOps.peopleList.filter(
                          item => item.position === "-"
                        )}
                      />
                    </section>

                    <ScrollElement name="CurrentJobs">
                      <section className="jobs">
                        <Container className="Layout-container">
                          <div className="cover">
                            <Slide style={{ "--sal-delay": "0.2s" }}>
                              <h2 className="fw-bold">Currently hiring for</h2>
                            </Slide>
                            <Jobs />
                            <div className="apply">
                              <Row>
                                <Col className="left didnt-find" lg={8}>
                                  <h4 className="fw-bold">
                                    Didn't find what you're looking for?
                                  </h4>
                                  <span className="p16">
                                    <p>
                                      Send us your resume anyway, because we're
                                      always looking for amazing talent to join
                                      our dream team. We'll be in touch.
                                    </p>
                                  </span>
                                </Col>
                                <Col className="right" lg={4}>
                                  <Buttons
                                    theme="secondary"
                                    arrow
                                    text="APPLY NOW"
                                    onClick={() =>
                                      setPopupVisibility(!popupVisibility)
                                    }
                                  />
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </Container>
                      </section>
                    </ScrollElement>

                    <section className="faq">
                      <center>
                        <div className="outer">
                          <Container className="Layout-container">
                            <Slide delay="200">
                              <h2>FAQs </h2>
                            </Slide>
                            <Slide delay="200">
                              <CustomAccordion items={data.SuperOps.faqs} />
                            </Slide>

                            <Slide delay="200">
                              <Row>
                                <Col lg={5}>
                                  <h5 className="pricing-faq-title">
                                    Still curious?
                                  </h5>
                                </Col>
                                <Col lg={7}>
                                  <p className="pricing-faq-desc">
                                    {/* We're available round the clock to answer
                                    your questions and help you find the right
                                    plan. <span id="chatId2">Ping us</span> or drop us
                                    a line at sales@superops.com. */}
                                    {/* Our <span id="chatId2">Help center</span> is open 24/7. You can also <span id="chatId3">reach out to us</span> .We’re here to help. */}
                                    Have more questions, write to us at{" "}
                                    <a
                                      href="mailto:careers@superops.com."
                                      id="chatId4"
                                    >
                                      <span>careers@superops.com.</span>
                                    </a>{" "}
                                    You'll hear back from us in 48 hours.
                                  </p>
                                </Col>
                              </Row>
                            </Slide>
                          </Container>
                        </div>
                      </center>
                    </section>
                  </Frame>
                </div>
              )
            })}
          </div>
        )}
      />
    </>
  )
}

export default Careers
