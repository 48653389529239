import { StaticQuery, graphql } from "gatsby"
import React, { useState } from "react"
import { Col, Row } from "react-bootstrap"
import Slide from "../../components/utility/slide_content"
import Buttons from "./../common/button"

function FeaturedJobs() {
  const [itemsToBeShown, setItemsToBeShown] = useState(5)

  const [listCount, setListCount] = useState(0)

  var currentItem = 1

  return (
    <>
      <StaticQuery
        query={graphql`
          query CareersJobs {
            SuperOps {
              careers(where: { isActive: Yes }, orderBy: createdAt_DESC) {
                tags {
                  name
                }
                jobTitle
                slug
                natureOfJob
              }
            }
          }
        `}
        render={data => (
          <>
            {setListCount(data.SuperOps.careers.length)}
            {data.SuperOps.careers.map((c, i) => {
              const { tags, jobTitle, slug, natureOfJob } = c
              return (
                <a href={`/careers/${slug}`} className="no-deco">
                  <Slide style={{ "--sal-delay": "0.2s" }}>
                    <div
                      className="item position-relative"
                      style={{
                        display: currentItem > itemsToBeShown && "none",
                      }}
                    >
                      <span className="dspnone">{currentItem++}</span>
                      <Row>
                        <Col lg={9}>
                          <h6>{jobTitle}</h6>
                          <div className="p14 left lowercase">
                            <p> {tags[0].name} </p>
                          </div>
                        </Col>

                        <Col lg={3}>
                          <div className="p14 right">
                            <p> {natureOfJob} </p>
                          </div>
                        </Col>
                      </Row>
                      <img
                        src="https://us-west-2.graphassets.com/AsRMKMrtKTFW6TGbr4KgUz/2j9zFnn3SWGuSdxuUPVA"
                        alt="arrow"
                        height="10"
                        width="10"
                        className="arrow position-absolute"
                      />
                    </div>
                  </Slide>
                </a>
              )
            })}

            <Slide style={{ "--sal-delay": "0.2s" }}>
              <Row className="info-row">
                <Col lg={4}>
                  {listCount <= 5 || itemsToBeShown >= listCount ? null : (
                    <Buttons
                      theme="primary"
                      arrow
                      text="VIEW MORE"
                      onClick={() => setItemsToBeShown(itemsToBeShown + 5)}
                    />
                  )}
                </Col>

                {/* <Col lg={8}>
                  <div className="p16 a16 last-cnt">
                    <p>
                      Didn't find what you're looking for? Send us your resume
                      anyway to <span>careers@superops.com</span>, because we're
                      always looking for amazing talent to join our dream team.
                      We'll be in touch.
                    </p>
                  </div>
                </Col> */}
              </Row>
            </Slide>
          </>
        )}
      />
    </>
  )
}

export default FeaturedJobs
