import React, { useState } from "react"
import { Container } from "react-bootstrap"
import ErrorIcon from "../../assets/images/signup-error.svg"
import FormBuilder from "../../components/common/FormBuilder/Form-Builder"
import "../../styles/component/popup-signup.scss"
import SVGIcon from "../common/SVGIcon"

function PopupCareer(props) {
  const [displayError, setDisplayError] = useState(false)
  const [success, setSuccess] = useState(false)

  const formdata = [
    {
      name: "firstname",
      type: "text",
      label: "Name",
      errorMessage: "Please enter a valid name",
    },
    {
      name: "TICKET.subject",
      type: "text",
      label: "Job Title",
      errorMessage: "Please enter a job title",
    },
    {
      name: "email",
      type: "email",
      label: "Email address",
      errorMessage: "Please enter a valid email",
    },
    {
      name: "mobilephone",
      type: "phone",
      label: "Mobile number",
      errorMessage: "Please enter a mobile number",
    },
    {
      name: "TICKET.content",
      type: "url",
      label: "Portfolio links",
      required: false,
      errorMessage: "Please enter a portfolio links",
    },
    {
      name: "cover_letter",
      type: "textarea",
      required: false,
      label: "Cover Letter",
      col: "10",
      errorMessage: "Please enter a valid message",
    },
    {
      name: "upload_file_link",
      type: "file",
      required: true,
      errorMessage: "Please upload your resume in .pdf and size < 10MB",
      acceptFiletype: "application/pdf",
    },
  ]

  const handleAfterSubmit = () => {
    setSuccess(true)
  }

  return (
    <div
      className="popup-signup"
      style={{ display: !props.visibility && "none" }}
    >
      <div
        className="wrap position-fixed"
        onClick={props.togglePopup}
        onKeyDown={props.togglePopup}
        role="button"
        tabIndex={0}
      >
        {/*  */}
      </div>

      <Container className="position-fixed">
        <div className="overflow">
          <div
            className="close position-absolute"
            onClick={props.togglePopup}
            onKeyDown={props.togglePopup}
            role="button"
            tabIndex={0}
          >
            <img
              src="https://us-west-2.graphassets.com/AsRMKMrtKTFW6TGbr4KgUz/zpyoXoSXRuWnSQ1KauLF"
              alt="close"
              height="24"
              width="24"
            />
          </div>

          <div className="forms">
            <span
              className="p16 info"
              style={{
                display: !displayError ? "block" : "none",
              }}
            >
              <center>{!success && <p> {props.title} </p>}</center>
            </span>
            <div
              className="api-error p14"
              style={{
                display: displayError ? "block" : "none",
              }}
            >
              <img
                src={ErrorIcon}
                height="10"
                width="10"
                className="position-relative"
                alt="img"
              />
              <p className="text-start mt16">
                Oops, we've hit a glitch. Try entering the details again.
              </p>
            </div>
            <div>
              {!success ? (
                <FormBuilder
                  formClassName="forms"
                  errorClassName="error-message"
                  inputErrorClassName="error-active"
                  data={formdata}
                  buttonText={"SUBMIT"}
                  buttonClassName="primary"
                  endpoint={process.env.HUBSPOT_CAREERS_API_ENDPOINT}
                  url={process.env.HUBSPOT_CAREERS_API_URL}
                  formName="CareersPage - Superops"
                  afterSubmit={handleAfterSubmit}
                  errorHandler={(d, res) => {
                    setDisplayError(true)
                  }}
                  fileUpload
                />
              ) : (
                <>
                  <div className="text-center mb24">
                    <SVGIcon name="upload-file-success" />

                    <h3 className="mb24 fw-bold">Done!</h3>

                    <div className="mb50 text-grey">
                      <p className="p16 ">
                        {" "}
                        Thank you for taking the time to apply. We’ll take a
                        look at your profile and get in touch when have an
                        opportunity that fits you!{" "}
                      </p>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default PopupCareer
